import React, {useEffect} from "react"
import styled from "styled-components"
import Spinner from 'components/common/spinner'
import Revealing from './revealing'
import ErrorMessage from "components/common/errorMessage"

const StyledContainer = styled.div`
  background: white;
  min-height: 20rem;
  border-radius: 0.62rem;
  border-top: 0.3125rem solid ${props =>props.color};
  .empty {
    height: 17rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Result = (props) => {
  useEffect(() => {
    const email = props.email && props.email.replace(/\s+/g, '+').toLowerCase();
    props.getJobRevealing({jobKey: props.jobKey, email: email})
    // scrollTo('#result')
  },[])

  if (props.jobRevealingResult.r) {
    return (
      <StyledContainer color="#9F7AEA">
        <Spinner />
      </StyledContainer>
    )
  } else if (props.jobRevealingResult.s && props.jobRevealingResult.payload && props.jobRevealingResult.payload.data) {
    return (
      <StyledContainer color="#9F7AEA">
        <Revealing jobRevealingResult={props.jobRevealingResult.payload.data} />
      </StyledContainer>
    )
  } else {
    return (
      <StyledContainer color="#9F7AEA">
        <div className="empty">
          <ErrorMessage
            title="Something went wrong"
            subtitle="Please retry again later."
            type="error"
            min="true"
          />
        </div>
      </StyledContainer>
    )
  }
}

export default Result;