import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { postJobRevealing, getJobRevealing } from "store/actions/job"
import { getSearchParams } from "gatsby-query-params";

import GlobalStateProvider from "context/provider"
import ContentWrapper from "styles/contentWrapper"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/category/hero"
import Clients from "components/sections/clients"
import Demos from "components/sections/category/demos"
import PrimaryCard from "components/common/primaryCard"
import Grid from "components/sections/grid"
import Breadcumbs from "components/common/breadcumbs"

import Revealing from "components/job/revealing"
import docImg from "content/images/search.svg"

const jobParsingImg = "https://hrflow-ai.imgix.net/labs/job-parsing.png?w=350&h=350&fit=clip"
const documentLinking = "https://hrflow-ai.imgix.net/labs/document-linking.png?w=350&h=350&fit=clip"
const textImagingImg = "https://hrflow-ai.imgix.net/labs/job-revealing.png?w=350&h=350&fit=clip"

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
`
const JobRevealingPage = (
  {
    data,
    getJobRevealing,
    jobRevealingResult,
    postJobRevealing,
    job,
  }) => {
  const { frontmatter } = data.index.edges[0].node
  const { seoTitle, useSeoTitleSuffix, useSplashScreen } = frontmatter
  const queryParams = getSearchParams()

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }


  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout>
        <SEO
          frontmatter={frontmatter}
        />
        <Breadcumbs
          color="#9F7AEA"
          text="Text analysis"
          link="/text-analysis/"
          logo={docImg}
        />
        <Hero
          content={data.hero.edges}
          color="#9F7AEA"
          resultPage={queryParams?.key}
          docs="https://developers.hrflow.ai/reference/text-revealing"
        />
        <Grid form>
          <Revealing
            queryParams={queryParams}
            getJobRevealing={getJobRevealing}
            jobRevealingResult={jobRevealingResult}
            postJobRevealing={postJobRevealing}
            response={job}
          />
        </Grid>
        <Clients content={data.clients.edges} category />
        <Demos content={data.demos.edges}>
          <StyledGrid className="mt-2">
              <PrimaryCard
                image={documentLinking}
                color="#9F7AEA"
                title="Text Linking"
                link="/text-analysis/linking/"
                subtitle="Enter a word and get its Top 10 closest words whether it is a person name, school, organization, skill, interest, country and more."
              />
              <PrimaryCard
                image={jobParsingImg}
                color="#9F7AEA"
                title="Text Parsing"
                link="/text-analysis/parsing/"
                subtitle="Enter an unstructured text and extract its semantic entities — such as companies, locations, tasks, skills, interests, durations, dates and more."
              />
              <PrimaryCard
                image={textImagingImg}
                color="#9F7AEA"
                title="Text Imaging"
                link="/text-analysis/imaging/"
                subtitle="Enter a job description to generate an image that showcases the tasks required from your future employees."
              />
          </StyledGrid>
        </Demos>
      </Layout>
    </GlobalStateProvider>
  )
}

JobRevealingPage.propTypes = {
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  job: state.job.jobRevealing,
  jobRevealingResult: state.job.jobRevealingResult,
})

export default connect(mapStateToProps, { postJobRevealing, getJobRevealing })(JobRevealingPage)

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/job/revealing/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            seoImage
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/job/revealing/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            description
          }
        }
      }
    }
    clients: allMdx(filter: { fileAbsolutePath: { regex: "/home/clients/" } }) {
      edges {
        node {
          exports {
            shownItems
            clients {
              alt
              logo {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 300, quality: 90, placeholder: NONE)
                }
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    demos: allMdx(filter: { fileAbsolutePath: { regex: "/job/revealing/demos/" } }) {
      edges {
        node {
          frontmatter {
            label
            title
            titlePrefix
            description
          }
        }
      }
    }
  }
`